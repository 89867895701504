import MenuIcon from "@mui/icons-material/Menu";
import { useEffect, useRef, useState } from "react";
import HomeSection from "../../Components/HomeSection/HomeSection";
import NavBar from "../../Components/NavBar/NavBar";
import ProcessSection from "../../Components/ProcessSection/ProcessSection";
import Services from "../../Components/Services/Services";
import Consulting from "../../Components/Consulting/Consulting";
import OurTeam from "../../Components/OurTeam/OurTeam";
import Testimonial from "../../Components/Testimonial/Testimonial";
import AboutUs from "../../Components/AboutUs/AboutUs";
import { useTranslation } from "react-i18next";
import HelaVideo from "../HelaVideo/HelaVideo";
import OurPortfolio from "../../Components/OurPortofolio/OurPortfolio";
import { IFullPageResponse } from "../../models/MainPage";
import http from "../../api/axios";
const MainPage = () => {
  const { t, i18n } = useTranslation();

  const sectionRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),

    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];

  const [fullData, setFullData] = useState<IFullPageResponse>();
  const fetchFullData = async () => {
    try {
      const response = await http.get<IFullPageResponse>(
        `/getSections/${i18n.language}`
      );
      setFullData(response.data);
    } catch (e) {}
  };
  useEffect(() => {
    fetchFullData();
  }, [i18n.language]);

  return (
    <div className="mb-[10px]  scroll-smooth ">
      <NavBar sectionRefs={sectionRefs} />
      <div className="flex flex-col gap-[100px]  px-2  ">
        <section ref={sectionRefs[0]}>
          <HomeSection />
        </section>
        <section ref={sectionRefs[1]}>
          <Services servicesData={fullData?.services} />
        </section>{" "}
        <section ref={sectionRefs[2]}>
          <AboutUs />
          <HelaVideo />
        </section>
        <section ref={sectionRefs[3]}>
          <ProcessSection processData={fullData?.process} />
        </section>
        <section ref={sectionRefs[4]}>
          <Consulting consultingData={fullData?.consulting} />
        </section>
        <section ref={sectionRefs[5]}>
          <OurTeam ourTeamData={fullData?.team_members} />
        </section>
        <section>
          <Testimonial testimonialData={fullData?.Testimonial} />
        </section>
        <section>
          <OurPortfolio />
        </section>
      </div>
      {/* The rest of the page */}
    </div>
  );
};

export default MainPage;
