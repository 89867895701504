import { Route, Routes } from "react-router-dom";
import MainPage from "../Pages/MainPage/MainPage";
import ContactUs from "../Pages/ContactUs/ContactUs";
import Footer from "../Components/general/Footer/Footer";
import { DirectionResolver } from "../utils/hooks";
import HelaVideo from "../Pages/HelaVideo/HelaVideo";

const Routing = () => {
  return (
    <div dir={DirectionResolver()}>
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/hela-video" element={<HelaVideo />} />
      </Routes>
      <Footer />
    </div>
  );
};

export default Routing;
