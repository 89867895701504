import React from "react";
import ContactCard from "../ContactUs/ContactCard/ContactCard";
import { useTranslation } from "react-i18next";
import { IService } from "../../models/MainPage";

const Services = ({ servicesData }: { servicesData?: IService[] }) => {
  const { t } = useTranslation();
  return (
    <div id="services" className=" w-full flex flex-col   items-center px-3  ">
      <div id="ContentRoot" className="    xl:w-[1225px] lg:w-full    ">
        <div id="Services1" className="text-lg   text-[#34c87c]">
          // <div className="text-[#1c1d22] contents">01 . {t("services")}</div>
        </div>
        <div className="text-4xl  font-medium text-[#0d1c22] mb-20 lg:w-1/2 md:w-full ">
          {servicesData && servicesData[0] && t(servicesData[0].main_title)}
        </div>
        <div className="grid lg:grid-cols-3 md:grid-cols-2 xs:grid-cols-1 place-items-center  gap-x-[72px] gap-y-[60px] ">
          {servicesData?.map((s) => (
            <ContactCard
              title={s.title}
              subTitle={s.paragraph}
              iconUrl={process.env.REACT_APP_BASE_IMAGE_URL + s.image}
              isService
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Services;
