import React, { useRef, useEffect } from "react";
import TeamCart from "./TeamCard/TeamCart";
import { useTranslation } from "react-i18next";
import { useDraggable } from "react-use-draggable-scroll";
import "./style.css";
import { TeamMember } from "../../models/MainPage";

const OurTeam = ({ ourTeamData }: { ourTeamData?: TeamMember[] }) => {
  const { t } = useTranslation();
  const ref =
    useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;
  const { events } = useDraggable(ref);
  useEffect(() => {
    const scrollInterval = setInterval(() => {
      if (ref.current) {
        const container = ref.current;
        const scrollableWidth = container.scrollWidth - container.clientWidth;
        const scrollPosition = container.scrollLeft;

        if (-scrollPosition + 2 > scrollableWidth) {
          container.scrollTo({ left: scrollableWidth, behavior: "smooth" });
        } else {
          container.scrollBy({ left: -450, behavior: "smooth" });
        }
      }
    }, 2000); // Scroll every two seconds

    return () => {
      clearInterval(scrollInterval);
    };
  }, []);
  return (
    <div id="our_team" className=" w-full flex justify-center  ">
      <div className="flex flex-col gap-20 w-full items-start xl:w-[1225px] lg:w-full ">
        <div className="flex flex-col gap-3 lg:w-3/5 md:w-full items-start">
          <div className="text-lg  text-[#34c87c]">
            //{" "}
            <div id="OurTeam" className="text-[#1c1d22] contents">
              06 . {t("our_team")}
            </div>
          </div>
          <div className="text-4xl  font-medium text-[#0d1c22]">
            {t("we_listen_discuss_advise_and_then_take_action")}
          </div>
        </div>

        <div
          dir="rtl"
          className="flex items-center team-slider gap-x-[35px] overflow-x-auto flex-shrink-0 place-items-center w-full"
          {...events}
          ref={ref}
        >
          {ourTeamData?.map((t) => (
            <TeamCart
              name={t.Fullname}
              role={t.Job_title}
              image={process.env.REACT_APP_BASE_IMAGE_URL + t.image}
              tags={[...t.tags]}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default OurTeam;
