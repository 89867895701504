/* eslint-disable react/jsx-no-comment-textnodes */
import { useTranslation } from "react-i18next";
import Process from "./Process/Process";
import { IProcess } from "../../models/MainPage";

const ProcessSection = ({
  ref,
  processData,
}: {
  ref?: any;
  processData?: IProcess[];
}) => {
  const { t } = useTranslation();
  return (
    <div
      id="process"
      className="flex flex-col  items-center justify-center content-center w-full"
      ref={ref}
    >
      <div
        id="Background"
        className=" py-[86px]   xl:w-[1225px] lg:w-full  bg-[linear-gradient(180deg,_rgba(52,_200,_124,_0.1)_0%,rgba(0,_176,_140,_0.1)_100%)] bg-cover bg-50%_50% bg-blend-normal bg-no-repeat flex flex-col justify-between gap-24 items-center   rounded-lg"
      >
        <div className="flex flex-col gap-3 w-3/4 items-center">
          <div id="Proccess1" className="text-lg text-[#34c87c]">
            //{" "}
            <div id="Proccess" className="text-[#1c1d22] contents">
              04 . {t("process")}
            </div>
          </div>
          <div className="text-center text-4xl font-medium text-[#0d1c22] self-start w-full">
            {processData && processData[1] && t(processData[1].main_title)}
          </div>
        </div>

        <div className="flex flex-col gap-[60px] w-full">
          {processData?.map((p, index) => (
            <Process
              title={p.title}
              text={p.paragraph}
              imageLink={[
                process.env.REACT_APP_BASE_IMAGE_URL + p.color_image,
                process.env.REACT_APP_BASE_IMAGE_URL + p.black_image,
              ]}
              isEnd={index == processData.length - 1}
              isStart={index == 0}
              reverse={index % 2 !== 0}
              id={p.id}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProcessSection;
